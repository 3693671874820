html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.site-main {
  flex: 1 0 auto;
}

.site-footer {
  flex-shrink: 0;
}
