button,
input[type='submit'] {
  cursor: pointer;
}

.button {
  background: var(--color-background-button);
  border: 2px solid var(--color-background-button);
  border-radius: 2rem;
  color: var(--color-text-button);
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.05rem;
  line-height: 1.25;
  max-inline-size: 100%;
  padding: 0.5rem 1.2rem;
  text-decoration: none;
  transition: transform 0.2s ease-in-out; /* transition-transform */
}

.button:hover {
  background: var(--color-background-button--hover);
  border-color: var(--color-background-button--hover);
  text-decoration: none;
}

.button:active {
  transform: scale(0.9);
}

.button--primary {
  background: var(--color-background-button-primary);
  border-color: var(--color-background-button-primary);
  color: var(--color-text-button-primary);
}

.button--primary:hover,
.button--primary:focus {
  background: var(--color-background-button-primary--interact);
  border-color: var(--color-background-button-primary--interact);
  color: var(--color-text-button-primary--interact);
  outline: none;
}

.button--secondary,
.button.active {
  background: var(--color-background-button-secondary);
  border-color: var(--color-background-button-secondary);
  color: var(--color-text-button-secondary);
}

.button--secondary:hover,
.button--secondary:focus {
  background: var(--color-background-button-secondary--interact);
  border-color: var(--color-background-button-secondary--interact);
  color: var(--color-text-button-secondary--interact);
  outline: none;
}

.button--outline {
  background: var(--color-background-button-outline);
  color: var(--color-text-button-outline);
}

.button--outline:hover,
.button--outline:focus {
  background: var(--color-background-button-outline--interact);
  border-color: var(--color-background-button-outline--interact);
  color: var(--color-text-button-outline--interact);
}

.button--danger,
.button--error {
  background: var(--color-error);
  border-color: var(--color-error);
  color: var(--color-text-primary);
  font-weight: 700;
}
