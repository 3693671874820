img.fit,
video.fit {
  block-size: 100%;
  inline-size: 100%;
}

img.fit--contain,
video.fit--contain {
  object-fit: contain;
}

img.fit--cover,
video.fit--cover {
  object-fit: cover;
}
