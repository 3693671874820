.timeline-stamp::before{
    position: absolute;
    content: '';
    left: -26px; /* Positionieren des Kreises auf 'Timeline' - 'Mobile'*/
    top: 20%;
    width: 1rem; /* Größe des Kreises */
    height: 1rem; /* Größe des Kreises */
    background-color: #9ca3af; /* Farbe text-gray-400 von Tailwind*/
    border-radius: 50%; /* Macht das Element rund */
}

@media screen and (min-width: 700px) {
    .timeline-stamp::before {
        left: -27px; /* Positionieren des Kreises auf 'Timeline' - 'Tablet' */
    }  
}

@media screen and (min-width: 1200px) {
    .timeline-stamp::before {
        left: -28px; /* Positionieren des Kreises auf 'Timeline' - 'Desktop' */
    }
    
}
@media screen and (min-width: 1550px) {
    .timeline-stamp::before {
        left: -29px; /* Positionieren des Kreises auf 'Timeline' - 'Desktop - Ultrawide' */
    }
    
}