/*
The icon layout helps aligning icons that accompany text.
Uses the emerging `cap` unit (https://drafts.csswg.org/css-values/#cap) and
`em` as a fallback.
Suggested markup:

<span class="with-icon">
  <svg class="icon">
    <use href="/path/to/icons.svg#my-icon"></use>
  </svg>
  Close
</span>

Where no (visible) textual label is provided, it’s important there is at 
least a screen reader perceptible label in the form of one of the following:

1. Visually hide a textual label (probably supplied in a <span>).
2. Add a <title> to the <svg>.
3. Add an aria-label directly to the parent (e.g. <button>) element.

*/

.icon {
  block-size: 1.25em;
  block-size: 2cap;
  /* TODO revise icon alignment and size */
  display: inline-block;
  fill: currentColor;
  inline-size: 1.25em;
  inline-size: 2cap;
  stroke-width: 2;
  vertical-align: text-bottom;
}
