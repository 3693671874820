.block-size--full {
  block-size: 100% !important;
}

.inline-size--fit {
  inline-size: fit-content !important;
}

.inline-size--full {
  inline-size: 100% !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-1 {
  margin: var(--s1) !important;
}

.margin-2 {
  margin: var(--s2) !important;
}

.margin-3 {
  margin: var(--s3) !important;
}

.margin-4 {
  margin: var(--s4) !important;
}

.margin-block-1 {
  margin-block: var(--s1) !important;
}

.margin-block-2 {
  margin-block: var(--s2) !important;
}

.margin-block-3 {
  margin-block: var(--s3) !important;
}

.margin-block-4 {
  margin-block: var(--s4) !important;
}

.margin-block-end-1 {
  margin-block-end: var(--s1) !important;
}

.margin-block-end-2 {
  margin-block-end: var(--s2) !important;
}

.margin-block-start-0 {
  margin-block-start: 0 !important;
}

.margin-block-start-1 {
  margin-block-start: var(--s1) !important;
}

.margin-block-start-2 {
  margin-block-start: var(--s2) !important;
}

.margin-block-start-3 {
  margin-block-start: var(--s3) !important;
}

.margin-inline-1 {
  margin-inline: var(--s1) !important;
}

.margin-inline-2 {
  margin-inline: var(--s2) !important;
}

.margin-inline-start-1 {
  margin-inline-start: 1em !important;
  margin-inline-start: 1.5cap !important;
}

.margin-inline-start-2 {
  margin-inline-start: 2em !important;
  margin-inline-start: 3cap !important;
}

.max-inline-size-full {
  max-inline-size: 100% !important;
}

.max-inline-size-none {
  max-inline-size: none !important;
}

.min-inline-size-150 {
  min-inline-size: 150px !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-0-5 {
  padding: 0.5rem !important;
}

.padding-1 {
  padding: var(--s1) !important;
}

.padding-2 {
  padding: var(--s2) !important;
}

.padding-3 {
  padding: var(--s3) !important;
}

.padding-4 {
  padding: var(--s4) clamp(var(--s0), 5%, var(--s4)) !important;
}

.padding-block-1 {
  padding-block: var(--s1) !important;
}

.padding-block-2 {
  padding-block: var(--s2) !important;
}

.padding-block-3 {
  padding-block: var(--s3) !important;
}

.padding-block-4 {
  padding-block: var(--s4) !important;
}

.padding-inline-0 {
  padding-inline: 0 !important;
}

.padding-inline-1 {
  padding-inline: var(--s1) !important;
}

.padding-inline-2 {
  padding-inline: var(--s2) !important;
}

.padding-inline-3 {
  padding-inline: var(--s3) !important;
}

.padding-inline-4 {
  padding-inline: var(--s4) !important;
}

.pos-rel {
  position: relative !important;
}

.rounded {
  border-radius: var(--border-radius) !important;
}
