.bg-primary {
  background-color: var(--color-primary) !important;
}

.bg-error {
  background-color: var(--color-error) !important;
}

.bg-warning {
  background-color: var(--color-warning) !important;
}

.fg-error {
  color: var(--color-error) !important;
}

.fg-info {
  color: var(--color-info) !important;
}

.fg-link {
  color: var(--color-link) !important;
}

.fg-main {
  color: var(--color-text-main) !important;
}

.fg-muted {
  color: var(--color-text-muted) !important;
}

.fg-primary {
  color: var(--color-primary) !important;
}

.fg-success {
  color: var(--color-success) !important;
}

.fg-white {
  color: var(--color-white) !important;
}
