.splide__pagination{
    position: absolute;
    bottom: -1.5rem;
}

.splide__pagination__page.is-active{
    background-color: var(--color-primary);
}

.news-slider .splide__arrows{
    display: none;
}