/* 
The cover layout centers one element vertically (the principal element) and 
optionally facilitates one element to be pushed towards the top and bottom each.
*/

.cover {
  display: flex;
  flex-direction: column;
  min-block-size: 100%;
  padding: var(--s1);
}
.cover > * {
  margin-block: var(--s1);
}
.cover > :first-child:not(.cover__center) {
  margin-block-start: 0;
}
.cover > :last-child:not(.cover__center) {
  margin-block-end: 0;
}
.cover > .cover__center {
  margin-block: auto;
}

.cover--full {
  min-block-size: 100vh;
}

.cover--loose > * {
  margin-block: var(--s3);
}
