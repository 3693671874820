/* Diese Styles sind für den HTML-Inhalt von importierten News */

/* News-Container */
/* die Klasse 'imported-news-content' wird beim Importieren automatisch dem äußersten Div-Container hinzugefügt */
/* .imported-news-content {} 
*/

/* Überschriften */
.imported-news-content h1, 
.imported-news-content h2, 
.imported-news-content h3, 
.imported-news-content h4, 
.imported-news-content h5, 
.imported-news-content h6 {
    margin: 1.2rem 0;
}

h1 *, 
h2 *, 
h3 *, 
h4 *, 
h5 *, 
h6 * {
    line-height: 1.5rem;
}

/* Text */
.imported-news-content p {
    margin: 1.2rem 0;
}

/* Links */
.imported-news-content a {
    color: var(--color-link) !important;
    cursor: pointer;
}
.imported-news-content a:hover {
    color: var(--color-link-- interact) !important;
}

/* Sortierte Listen */
.imported-news-content ol li {
    margin-left: 1.5rem;
    list-style-type: decimal;
}

/* Unsortierte Listen */
.imported-news-content ul li {
    margin-left: 1.5rem;
    list-style-type: disc;
}



