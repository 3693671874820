/* 
The center composition horizontally centers all content within the center
element. Using the Flexbox layout module it also supports intrinsic centering,
i.e. elements are centered based on their natural, content-based widths.
*/

.center {
  box-sizing: content-box;
  margin-inline: auto;
  max-inline-size: var(--measure);
  padding-inline: var(--s1);
}
