.application-form p{
    margin-bottom: 1rem;
}

.captcha{
    margin-bottom: 1rem;
}

.errorlist{
    color: red;
}