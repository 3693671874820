.site-search-open .site-search-toggle .icon-search {
  display: none;
}

.site-search-open .site-search-toggle .icon-cross {
  display: inline-block;
}

.site-search-toggle .icon-cross {
  display: none;
}

#js-site-search.site-search {
  background-color: var(--color-background-main);
  display: none;
}

.site-search-open #js-site-search.site-search {
  background: var(--color-primary);
  color: inherit;
  display: block;
  left: 0;
  margin: 0;
  padding-block: 1rem;
  top: 100%;
  width: 100%;
  z-index: var(--z5);
}

.search {
  align-items: stretch;
  display: flex;
  position: relative;
  width: 100%;
}

.search-input {
  border-radius: 2rem;
  box-shadow: inset 25px 0px 25px -25px rgba(0, 0, 0, 0.35),
    inset -25px 0px 25px -25px rgba(0, 0, 0, 0.35);
  padding-inline-start: 2rem;
  margin-left: 2rem;
}

.search .search-input {
  border: solid 2px var(--color-primary);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  flex: 1 1 auto;
  max-width: 100%;
}

.search-input-participant-map{
  border-radius: 1.5rem !important;
  height: 2.5rem;
  margin-left: 0rem !important;
}

.no-results{
  font: bold;
  font-size: 1.2rem;
  padding: 1rem;
  height: 10.5rem;
  width: 10.5rem;

}

.search-header .search-input{
  border: solid 2px var(--color-background-main);
}


.search .search-button {
  border-bottom-left-radius: 0;
  border-left: 0;
  border-top-left-radius: 0;
  flex: 0 1 150px;
  margin-right: 2rem;
  min-width: 100px;
}

.search-header .search-button{
  background-color: var(--color-background-main);
  color: var(--color-primary);
  border: solid 2px var(--color-background-main);
}

.search-header .search-button:hover{
  border: solid 2px var(--color-background-main);
  background-color: var(--color-primary);
  color: var(--color-background-main);
  
}

.search-results {
  margin: 0rem 2rem;
  background: var(--color-background-main);
  border: 3px solid var(--color-primary);
  border-top: 0;
  box-shadow: inset 25px 0px 25px -25px rgba(0, 0, 0, 0.35),
    inset -25px 0px 25px -25px rgba(0, 0, 0, 0.35);
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  text-align: left;
  z-index: 9999 !important;
  top: 45px; /*Höhe der Searchbar*/
}

#js-site-search .search-results {
  top: 100%;
}

.search-results .results-list {
  max-height: 20rem;
  overflow-y: auto;
  background-color: var(--color-background-main);
}

.search-results .results-list a {
  display: block;
  font-size: 1.1rem;
  padding: 0.3rem .6rem;
  margin-inline: 3px;
  text-decoration: none;
}
.search-results .results-list p {
  padding: .1rem .6rem;
  width:fit-content;
}

.search-results .results-list a:focus,
.search-results .results-list a:hover {
  background: var(--color-primary);
  color: var(--color-text-primary);
  outline: none;
}

.search.show-results .search-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.search .results-search-button {
  display: block;
  margin: 1.5rem auto;
}

#js-site-search .search.show-results .results-search-button {
  display: none;
}

.search.show-results .search-button {
  border-bottom-right-radius: 0;
}

.search.show-results .search-results {
  display: block;
}

@media screen and (max-width: 899px) {
  .search.show-results .search-input {
    border-bottom-left-radius: 0 !important;
  }
}

@media screen and (min-width: 900px) {
  .search-results {
    top: 100%;
  }
  .search.show-results .results-search-button {
    display: none;
  }
}
