.pagination-container-desktop{
  padding-top: 2.5rem;
}

.pagination-container-mobile{
  padding-top: 2.5rem;
  display: none;
}

.pagination{
  display: flex;
  align-items: center; 
  justify-content: center;
  list-style-type: none !important;
  flex-wrap: wrap;
  width:fit-content;
  padding: 0;
  margin: auto; 
  border: 1px solid var(--color-background-box);
  border-radius: 5px;
  box-shadow: 1px 2px 6px 0px var(--color-background-box);   
}

.page-dots{
  color: var(--color-text-muted);
}

.pagination a {
  border-radius: 5px;
  margin: .5rem .2rem;
  padding: .3rem .8rem;  
}

.pagination .page-number:first-child {
  margin-left: .8rem;  
}
.pagination .page-number:last-child {
  margin-right: .8rem;
}

.pagination .page-number.current{
  background-color: var(--color-primary);
  color: var(--color-text-primary); 
}

.pagination .page-number.current a {
  color: var(--color-text-primary);
}

.pagination .page-number:hover:not(.current,.page-dots){
  background-color: var(--color-background-box);
  transition: .15s ease-in-out
}
.pagination a:hover{
  text-decoration: none;
}

@media screen and (max-width: 450px){
  .pagination-container-desktop{
    display: none;
  }
  .pagination-container-mobile{
    display:block;
  }
  .pagination a {
    margin: .5rem .4rem;
    padding: .3rem .6rem;  
  }
  
  .pagination .page-number:first-child {
    margin-left: .6rem;  
  }
  .pagination .page-number:last-child {
    margin-right: .6rem;
  }
}
