/* 
The Switcher element
(based on http://www.heydonworks.com/article/the-flexbox-holy-albatross) 
switches a Flexbox context between a horizontal and a vertical layout at a 
given, container-based breakpoint.
The breakpoint at which the layout switches can be configured by setting the
`--threshold` variable (default: 30rem).
Additionally, the layout is switched to the vertical one if there are more than
5 child elements present.
*/

.switcher {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gutter, var(--s1));
  --threshold: 30rem;
}
.switcher > * {
  flex-basis: calc((var(--threshold) - 100%) * 999);
  flex-grow: 1;
}
.switcher > :nth-last-child(n + 5),
.switcher > :nth-last-child(n + 5) ~ * {
  /* Switch to a vertical configuration if there are more than 4 child elements */
  flex-basis: 100%;
}
