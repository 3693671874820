.image-overflow-and-text-block {
  display: flex;
  flex-direction: column;
  gap: var(--s1);
  padding: var(--s4) clamp(var(--s0), 5%, var(--s4));
}

.image-overflow-and-text-block .image {
  align-self: flex-end;
  border: 5px solid var(--color-primary);
  border-radius: 50%;
  max-width: 300px;
  padding: 10px;
  text-decoration: none !important;
}

.image-overflow-and-text-block .image img {
  border-radius: 50%;
}

@media screen and (min-width: 60rem) {
  .image-overflow-and-text-block {
    margin-inline-start: 10rem;
  }
  .image-overflow-and-text-block .text {
    padding-inline-start: 13rem;
  }
  .image-overflow-and-text-block .image {
    left: -10rem;
    max-width: 400px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
