.aspect-ratio-fixed {
  position: relative;
  display: block;
  /* Apply 16:9 format by default. */
  padding-block-end: 56.25%;
  overflow: hidden;
}

.aspect-ratio-fixed.aspect-ratio--1-1 {
  padding-block-end: 100%;
}

.aspect-ratio-fixed.aspect-ratio--4-3 {
  padding-block-end: 75%;
}

.aspect-ratio-fixed > *,
.aspect-ratio-flexible > * {
  position: absolute;
  inline-size: 100%;
  block-size: 100%;
  object-fit: cover;
}

.aspect-ratio--contain > * {
  object-fit: contain;
}

.aspect-ratio-flexible {
  position: relative;
  overflow: hidden;
}

.aspect-ratio-flexible:before {
  content: '';
  inline-size: 1px;
  margin-inline-start: -1px;
  /* Only FF supports float: inline-start out of the box, 2022-10-27 */
  float: left;
  float: inline-start;
  block-size: 0;
  padding-block-start: 56.25%;
}

.aspect-ratio-flexible.aspect-ratio--1-1:before {
  padding-block-start: 100%;
}

.aspect-ratio-flexible.aspect-ratio--4-3:before {
  padding-block-start: 75%;
}

.aspect-ratio-flexible:after {
  content: '';
  display: table;
  clear: both;
}
