.pdf-preview-size-fullwidth {
    width: 100%;
}

.pdf-preview-size-large {
    width: 75%;
}

.pdf-preview-size-medium {
    width: 50%;
}

.pdf-preview-size-small {
    width: 25%;
}