.hide {
  display: none !important;
}

@media screen and (max-width: 599px) {
  .show-starting-at-md {
    display: none !important;
  }
}

@media screen and (min-width: 600px) {
  .hide-starting-at-md {
    display: none !important;
  }
}

@media screen and (max-width: 1199px) {
  .show-through-md {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .hide-starting-at-lg {
    display: none !important;
  }
}

.overflow-y-auto {
  overflow-y: auto !important;
}
