/* 
The Frame is mostly useful for cropping media (videos and images) to a 
desired aspect ratio, but can also control the aspect ratio of elements in 
different circumstances.
Images and videos are "replaced" elements, meaning they are replaced by the
eventually loaded source. Replaced elements can be cropped by stretching them
to the full size of the parent and setting `object-fit: cover`.
All other elements are centered by the Flexbox module set on the `frame` 
element itself. `overflow: hidden` hides any overflowing content.
The aspect ratio can be controlled by setting the --n (Numerator) and --d
(Denominator) values (default: 16/9).
*/

.frame {
  --n: 16; /* width */
  --d: 9; /* height */
  align-items: center;
  aspect-ratio: var(--n) / var(--d);
  /* Use flexbox centering for everything other than img or video, since 
     other elements don't support aspect-ratio. */
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.frame > img,
.frame > picture,
.frame > picture > img,
.frame > video {
  block-size: 100%;
  inline-size: 100%;
  object-fit: cover;
}

.frame--contain > img,
.frame--contain > picture,
.frame--contain > picture > img,
.frame--contain > video {
  object-fit: contain;
}
