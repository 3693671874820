.srt {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.srt:focus {
  clip: auto !important;
  display: inline-block;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
