.image-and-text-block {
  background: var(--background-color, transparent);
}

.image-and-text-block.layout-image-overflow {
  background: linear-gradient(
    to left,
    var(--color-background-main)
      min(calc(-1 * var(--threshold) - 2 * var(--s1) + 100%), 30%),
    var(--background-color, transparent)
      min(calc(-1 * var(--threshold) - 2 * var(--s1) + 100%), 30%)
  );
}

.image-and-text-block.flex-row-reverse.layout-image-overflow {
  background: linear-gradient(
    to right,
    var(--color-background-main)
      min(calc(-1 * var(--threshold) - 2 * var(--s1) + 100%), 30%),
    var(--background-color, transparent)
      min(calc(-1 * var(--threshold) - 2 * var(--s1) + 100%), 30%)
  );
}
