.font-size-1 {
  font-size: 1rem !important;
}

.font-size-big {
  font-size: 1.25em !important;
}

.font-size-small {
  font-size: 0.75em !important;
}

.font-style-italic {
  font-style: italic !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-end {
  text-align: end !important;
}

.text-strikethrough {
  text-decoration: line-through !important;
}

.text-transform-none {
  text-transform: none !important;
}

.text-transform-uppercase {
  text-transform: uppercase !important;
}
