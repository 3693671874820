:root {
  /* Modular Scale for Typography */
  --ratio: 1.1;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: .9rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));

  /* Colors */
  --color-primary: rgb(0, 74, 53);
  --color-primary--transparency: rgba(0, 74, 53,0.9);
  --color-secondary: rgb(63, 78, 79);
  --color-accent: rgb(255, 228, 64);
  --color-text-footer: rgb(44, 54, 57);
  --color-text-main: rgb(44, 54, 57);
  --color-text-muted: rgb(145, 145, 145);
  --color-text-primary: rgb(250, 250, 250);
  --color-text-secondary: rgb(250, 250, 250);
  --color-background-box: rgb(220, 215, 201);
  --color-background-footer: rgb(220, 215, 201);
  --color-background-header: rgb(245, 245, 245);
  --color-background-main: rgb(245, 245, 245);
  --color-background-site-navigation: rgba(245, 245, 245, 0.95);
  --color-logo: rgb(255, 255, 255);
  --color-link: rgb(63, 78, 79);
  --color-link--interact: rgb(48, 60, 61);
  --color-mark-background: #e2b900;
  --color-background-text-overlay: rgba(255, 255, 255, 0.5);
  --color-text-overlay: rgb(20, 20, 20);
  --color-white: rgb(250, 250, 250);

  /* Button Colors */
  --color-background-button: var(--color-primary);
  --color-text-button: rgb(255, 255, 255);
  --color-background-button--hover: var(--color-accent);
  --color-text-button--hover: rgb(255, 255, 255);
  --color-background-button-primary: var(--color-primary);
  --color-text-button-primary: var(--color-text-primary);
  --color-background-button-primary--interact: var(--color-accent);
  --color-text-button-primary--interact: var(--color-text-primary);
  --color-background-button-secondary: var(--color-secondary);
  --color-text-button-secondary: var(--color-text-secondary);
  --color-background-button-secondary--interact: var(--color-accent);
  --color-text-button-secondary--interact: var(--color-text-secondary);
  --color-background-button-outline: var(--color-background-main);
  --color-text-button-outline: var(--color-text-main);
  --color-background-button-outline--interact: var(--color-text-main);
  --color-text-button-outline--interact: var(--color-background-main);

  /* Status Colors */
  --color-error: #ef4444;
  --color-background-error: #fecaca;
  --color-warning: #b45309;
  --color-background-warning: #fde68a;
  --color-info: #1e293b;
  --color-background-info: #bfdbfe;
  --color-success: #166534;
  --color-background-success: #bbf7d0;
  --color-debug: #4c1d95;
  --color-background-debug: #c4b5fd;

  /* Layout */
  --border-radius: 8px;
  --border-thin: var(--s-5);
  --border-thick: var(--s-2);
  --box-shadow: 6px 10px 12px 2px rgba(0, 0, 0, 0.7);
  --box-shadow-light: 6px 8px 8px 1px rgba(0, 0, 0, 0.4);
  --max-inline-size: 1440px;
   --measure: 130ch;
  --space: var(--s1);
  /* -- Sidebar */
  --inline-size-sidebar: 15rem;
  --min-inline-size-content: 65%;

  /* Text & Fonts */
  /* --font-family-main: 'Source Serif 4', system-ui, sans-serif;
  --font-family-header: 'Open Sans', system-ui, sans-serif; */
  --font-weight-header: 600;
  --font-weight-main: 400;
  --letter-spacing: 0.03rem;
  --line-height: 1.25;
  --line-height-small: 1;
  --text-transform-menu: uppercase;

  /* Painted Shapes */
  --generic-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 2px 6px 2px rgba(60, 64, 67, 0.15);

  /* Z-Indexes */
  --z-1: -1;
  --z0: 1;
  --z1: 10;
  --z2: 30;
  --z3: 50;
  --z4: 1500; /* Leaflet's biggest z-index seems to be 1000 */
}
