.interactive-map-container {
  border: 1px solid var(--color-text-muted);
  display: flex;
  flex-direction: column;
  max-inline-size: 100%;
  position: relative;
}

.interactive-map {
  min-block-size: 500px;
}

.interactive-map-entries {
  block-size: auto;
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1rem;
}

.interactive-map-entries > * {
  flex: 0 0 auto;
}

.interactive-map-entries button {
  background: transparent;
  border: 1px solid var(--color-text-muted);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-light);
  font-size: 0.875rem;
  max-inline-size: 170px;
  padding: 0;
}

.interactive-map-entries button.active {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-text-primary);
}

.interactive-map-entries button img {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.interactive-map-entries button .frame {
  max-block-size: 150px;
}

.interactive-map-entries button p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: 900px) {
  .interactive-map-container {
    aspect-ratio: 4 / 3;
    flex-direction: row;
    inline-size: 100%;
    /* Safety measure in case aspect-ratio is not supported */
    max-block-size: 1500px;
  }

  .interactive-map {
    flex: 1 1 80%;
  }

  .interactive-map-entries {
    flex: 0 1 200px;
    flex-direction: column;
    max-block-size: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
/* 
.interactive-map {
  flex: 1 0 100%;
  min-block-size: 500px;
}

.interactive-map-entries {
  background-color: #fff;
  block-size: 100%;
  flex: 0 0 15rem;
  font-size: 0.85rem;
  box-shadow: inset 0 10px 5px rgba(0, 0, 0, 0.4),
    inset 0px -10px 5px rgba(0, 0, 0, 0.4);
  inline-size: 18%;
  max-block-size: 500px;

  max-inline-size: 15rem;
  min-inline-size: 10rem;
  overflow-y: auto;
  padding: 1rem 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--z4);
}

.interactive-map-entries button {
  background: transparent;
  border: 1px solid var(--color-text-muted);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-light);
  padding: 0;
}

.interactive-map-entries button img {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
} */
