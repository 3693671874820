.h1 {
  font-size: var(--s3) !important;
}

.h2 {
  font-size: var(--s2) !important;
}

.h3 {
  font-size: var(--s1) !important;
}

.h4,
.h5,
.h6 {
  font-size: var(--s0) !important;
}

.font-family-header {
  font-family: var(--font-family-header) !important;
}

.font-family-main {
  font-family: var(--font-family-main) !important;
}
