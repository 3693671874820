.site-footer {
  background-color: var(--color-background-footer);
  color: var(--color-text-footer);
  margin-block-start: 5rem;
  padding-block-end: 5rem;
}

.site-footer * {
  color: inherit;
}

.site-footer .icon {
  block-size: 2.3em;
  block-size: 3cap;
  inline-size: 2.3em;
  inline-size: 3cap;
}
