/* 
The sidebar layout places two elements next to each other, with one being the
narrower sidebar and the other being the main content.
Below a certain breakpoint, the sidebar content will be placed below the main
content.
The order by default is non-sidebar - sidebar, but can be reversed by adding 
the `reverse` class to the `with-sidebar` element. The reversal of the elements
only applies for the side-by-side configuration, not the vertically stacked
configuration on smaller screens.
*/

.with-sidebar {
  --sidebar-gap: var(--gap, 1.5rem);
  display: flex;
  flex-direction: column;
  gap: var(--sidebar-gap);
  justify-content: space-between;
}

@media screen and (min-width: 900px) {
  .with-sidebar {
    flex-direction: row;
  }

  .with-sidebar > :first-child,
  .with-sidebar.sidebar-first > :last-child {
    flex: 1 1 var(--main-content-inline-size, 65%);
  }

  .with-sidebar > :last-child,
  .with-sidebar.sidebar-first > :first-child {
    flex: 1 1 var(--sidebar-inline-size, 15rem);
  }

  .with-sidebar.reverse {
    flex-direction: row-reverse;
  }
}

/* Placing Categories on top of the content instead below while in mobile view */
@media screen and (max-width: 900px){
  .categories-on-top{
    flex-direction:column-reverse;
  }
}

/* Converting Categorie list into an dropdown menu while in mobile view */
.categorie-mobile-headline{
  display: none;
  font-size: var(--s2);
}

@media screen and (max-width: 900px){
  .categorie-desktop-headline{
    display: none;
  }
  .categorie-mobile-headline{
    display: block;
  }

  .categorie-mobile-headline span {
    position: relative;
    text-align: center;
    padding: 10px;
    background: var(--color-primary);
    color: var(--color-text-primary);
    font-size: 1em;
    font-variant: small-caps;
    cursor: pointer;
    display: block;
  }
  
  .categorie-mobile-headline span::after {
    float: right;
    right: 10%;
    font-size: 2.3rem;
    content: "\2023";
    
    /*for centering the pseudo element vertically*/
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(+90deg);
    transition: .4s ease;
  }
  
  .categorie-mobile-headline span.checked::after {
    transform: translateY(-50%) rotate(-90deg);
  }
  
  .slide {
    clear: both;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    text-align: center;
    transition: max-height .4s ease;
  }
  
  .slide.slide-visible {
    height: 1000px;
    transition: height 0.4s ease;
  }
  
  .slide a {
    padding: 5px;
  }
  
  #touch {
    position: absolute;
    opacity: 0;
    height: 0px;
  } 
}

