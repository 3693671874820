.custom-alert {
    display: inline-flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem .5rem 0.4rem 0.5rem;
    margin-bottom: 1rem;
    color: var(--color-text-secondary);
    background-color: var(--color-primary);
    border-radius: 9999px;
    text-wrap: pretty;
}

.custom-alert:hover {
    text-decoration: none !important;
}

.custom-alert-new {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-accent);
    color: var(--color-primary);
    border-radius: 9999px;
    padding: 0.375rem 1rem;
    margin-right: 0.75rem;
    height:90%;
    box-shadow: var(--generic-shadow);
}

.custom-alert-text {
    position: relative;
    display: inline-flex;
}

.custom-alert-text::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -1px; /* Abstand zum Text */
    left: 0;
    background-color: var(--color-text-secondary);
    transition: width 0.25s ease-in-out;
}

.custom-alert:hover .custom-alert-text::after {
    width: 100%;
}

.custom-alert-icon {
    margin-left: 0.5rem;
    width: 1.25rem;
    height: 1.25rem;
}