/*
The cluster composition lays out child elements in a flexible grid-like 
structure while still allowing each child element its own individual width.
*/

.cluster {
  --gap: var(--space, 1.5rem);
  --align: center;
  --justify: center;
  align-items: var(--align);
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: var(--justify);
}
