.form__element {
  margin-bottom: 1.5rem;
  max-width: var(--measure);
  text-transform: lowercase;
  height: fit-content;
}

.form_field_label::first-letter {
  text-transform: uppercase;
}


.form__element > label + .form__input {
  margin-block-start: 0.25rem;
}

input,
select,
textarea {
  background: rgb(234, 234, 234);
  border-radius: 3rem;
  max-width: var(--measure);
  padding: 0.75rem !important;
  width: 100%;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
}

textarea {
  border-radius: 1rem;
}

input {
  border: 0;
  border-bottom: 1px solid var(--color-text-main-transparent);
  border-radius: 0;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  width: 100%;
} 

input[type='checkbox'],
input[type='radio'] {
  margin: 0;
  width: auto;
}

input:focus {
  border-color: var(--color-text-main);
}

label {
  font-weight: 700;
}

label.required::after {
  color: var(--color-error);
  content: '*';
  display: inline-block;
  font-weight: 700;
  margin-inline-start: 0.5em;
}

/* 
Hide the required indicator from specific fields by their ID.
These IDs are Python code dependent. 
*/
label[for='id_quantity'].required::after,
label[for='id_wishlist'].required::after {
  content: '' !important;
}

input:not([type='submit']):not([type='button']):not(.search-input) {
  border: 0;
  padding:0.3rem 0.5rem;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
}

input:not([type='submit']):not([type='button']):not(.search-input),
select,
textarea {
  border-radius: .3rem;
}

.application-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.application-form > * {
  width: 100%;
  max-width: 1000px;
}

.button--form{
  width: 100% !important;
  max-width: 600px !important;
}
