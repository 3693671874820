.gallery-slider .slide-text {
  background: rgba(20, 20, 20, 0.8);
  color: var(--color-white);
  max-inline-size: var(--measure);
  padding: 1rem;
}

.slide-content-box{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--color-primary) !important;
}

.button--slider:hover{
  background-color: var(--color-secondary) !important;
}

/* Hide the text on small screens */
@media screen and (max-width: 599px) {
  .gallery-slider .slide-text {
    background: none;
    
  }
  .slide-content-box{
    padding: 2rem;
  }

  .gallery-slider .slide-text .text {
    display: none;
    /* background: none;
    color: var(--color-text-main);
    inset-block-start: unset;
    inset-inline-start: unset;
    position: relative !important;
    transform: none; */
  }
}

@media screen and (min-width: 1000px) {
  .gallery-slider .slide-text {
    padding: 1.5rem;
  }
}
