.badge {
  background-color: var(--color-primary);
  border-radius: 50%;
  color: var(--color-text-primary);
  display: block;
  font-size: 90%;
  height: 1.25rem;
  text-align: center;
  width: 1.25rem;
}

.badge.badge--counter {
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
}
