/* 
The imposter element allows centrally positioning of an element over the 
viewport, the document or a selected "positioning container" element, e.g. 
to purposefully obscure content (for example content that is locked behind
a paywall).
*/

.imposter {
  inset-block-start: 50%;
  inset-inline-start: 50%;
  /* Prevent the imposter element from overflowing the positioning 
     element, potentially obscuring content around it */
  overflow: auto;
  /* Position the element's top left corner in the center */
  position: var(--positioning, absolute);
  /* Reposition the element so that the center of the element is the 
     center of the positioning container */
  transform: translate(-50%, -50%);
}

.imposter--contain {
  /* Provide a margin between the imposter and the edge of the 
     positioning element */
  --content-margin: var(--space, 1.5rem);
  max-block-size: calc(100% - (var(--content-margin) * 2));
  max-inline-size: calc(100% - (var(--content-margin) * 2));
}

.imposter--end {
  inset-block-start: 100%;
  transform: translate(-50%, -100%);
}

.imposter--full-inline-size {
  inline-size: 100%;
}
