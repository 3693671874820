.redirect-modal {
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z4);
}

.redirect-modal.redirect-modal--visible {
  display: flex;
}

.redirect-modal .redirect-content {
  background: var(--color-background-main);
  border: 2px solid var(--color-primary);
  border-radius: 3px;
  box-shadow: 6px 10px 12px 2px rgba(0, 0, 0, 0.7);
  display: block;
  margin: 0 auto;
  max-width: 90%;
  padding: 2.5rem 2rem 2rem;
  position: relative;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .redirect-modal .redirect-content {
    max-width: 60%;
  }
}

.redirect-modal .redirect-close-button {
  background: transparent;
  border: 0;
  box-sizing: content-box;
  height: 1rem;
  padding: 0.5rem 0.7rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: 1rem;
}
