/* 
A box layout can be used to visually group elements together, e.g. for a 
modal with text and inputs. 
The box is only concerned with itself and its contents. Properties like margin
etc. reach outside of the box and should be handled elsewhere.
*/

.box {
  --box-color: var(--color-text-main);
  --box-background: var(--color-background-main);
  --box-border-color: var(--box-color);
  background-color: var(--box-background);
  border: var(--border-thin) solid var(--box-border-color);
  color: var(--box-color);
  outline: var(--border-thin) transparent;
  outline-offset: calc(var(--border-thin) * -1);
  padding: var(--s1);
}

.box * {
  color: inherit;
}

.box--rounded {
  border-radius: var(--border-radius);
}

.box--large {
  padding: calc(var(--s1) * 2);
}

/* Colored Boxes */
.box--error {
  --box-color: var(--color-error);
  --box-background: var(--color-background-error);
}

.box--warning {
  --box-color: var(--color-warning);
  --box-background: var(--color-background-warning);
}

.box--info {
  --box-color: var(--color-info);
  --box-background: var(--color-background-info);
}

.box--success {
  --box-color: var(--color-success);
  --box-background: var(--color-background-success);
}

.box--debug {
  --box-color: var(--color-debug);
  --box-background: var(--color-background-debug);
}
