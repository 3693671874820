.blurry-image {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-block-end: 0;
  inset-inline-end: 0;
  overflow: hidden;
  z-index: var(--z-1, -1);
}

.blurry-image img {
  inline-size: 100%;
  block-size: 100%;
  filter: blur(16px);
  transform: scale(1.2);
  transform-origin: center;
  object-fit: cover;
}
