/* 
The Reel is a robust and efficient antidote to carousel/slider components.
It arranges all elements in a horizontal line without wrapping and handles
overflow automatically by scrolling just the reel element itself.
It is ideal for browsing categories of things: products, photographs etc.
*/

.reel {
  --space: 1rem;
  display: flex;
  height: auto;
  margin-inline: calc(var(--s1) * -1);
  overflow-x: auto;
  overflow-y: hidden;
  padding: var(--s1);
}

.reel > * {
  /* Just a `width` wouldn’t work because `flex-shrink: 1` (default) still applies */
  flex: 0 0 auto;
}

.reel > img {
  /* Reset for images */
  block-size: 100%;
  flex-basis: auto;
  inline-size: auto;
}

.reel > * + * {
  margin-inline-start: var(--space, 1.5rem);
}
